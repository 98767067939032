import Vue from 'vue'
import VueRouter from 'vue-router'
import Master from '@/components/Master'
import store from '../store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import("@/views/Login"),
    meta: { title: '登陆', login: false },
  },

  {
    path: '/match/template',
    component: () => import('@/views/match/template'),
    name: 'template',
    meta: { title: '赛事-模版', login: true }
  },

  // 赛事
  {
    path: '/',
    component: Master,
    redirect: '/dashboard',
    children: [
      // 控制台
      {
        path: '/dashboard',
        component: () => import('@/views/Dashboard'),
        name: 'dashboard',
        meta: { title: '控制台', login: true }
      },

      // 赛事相关
      {
        path: '/match/offical',
        component: () => import('@/views/match'),
        meta: { title: '赛事-甲方赛事', login: true },
      },
      {
        path: 'match/others',
        component: () => import('@/views/match/matchOther'),
        meta: { title: '赛事-第三方赛事', login: true },
      },
      {
        path: '/match/join/people',
        component: () => import('@/views/match/joinPeople'),
        name: 'joinPeople',
        meta: { title: '赛事-参赛人', login: true }
      },
      // {
      //   path: '/match/template',
      //   component: () => import('@/views/match/template'),
      //   name: 'template',
      //   meta: { title: '赛事-模版', login: true }
      // },
      {
        path: '/match/people/info',
        component: () => import('@/views/match/peopleInfo'),
        name: 'peopleInfo',
        meta: { title: '赛事-参赛人信息', login: true }
      },
      {
        path: '/match/all/people',
        component: () => import('@/views/match/allPeople'),
        name: 'allPeople',
        meta: { title: '赛事-参赛人信息', login: true },
      },
      {
        path: '/match/info',
        component: () => import('@/views/match/matchInfo'),
        name: 'matchInfo',
        meta: { title: '赛事-信息', login: true }
      },
      {
        path: '/match/edit',
        component: () => import('@/views/match/matchEdit'),
        name: 'matchEdit',
        meta: { title: '赛事-修改', login: true }
      },
      {
        path: '/match/add',
        component: () => import('@/views/match/matchAdd'),
        name: 'matchInfo',
        meta: { title: '赛事-添加', login: true }
      },
      {
        path: '/join/people/add',
        component: () => import('@/views/match/joinPeopleAdd'),
        name: '',
        meta: { title: '赛事分组-添加', login: true }
      },
      {
        path: '/join/people/info',
        component: () => import('@/views/match/joinPeopleInfo'),
        name: '',
        meta: { title: '赛事分组-详情', login: true }
      },
      {
        path: '/join/people/edit',
        component: () => import('@/views/match/joinPeopleEdit'),
        name: '',
        meta: { title: '赛事分组-修改', login: true }
      },
      {
        path: '/match/apply/quickEntry',
        component: () => import('@/views/match/quickEntry'),
        name: 'quickEntry',
        meta: { title: '赛事-快速报名', login: true }
      },

      // 动态管理
      {
        path: '/article/list',
        component: () => import('@/views/article'),
        name: '动态管理',
        meta: { title: '动态', login: true }
      },
      {
        path: '/article/add',
        component: () => import('@/views/article/create_and_edit'),
        name: '编辑动态',
        meta: { title: '动态', login: true }
      },
      {
        path: '/article/edit',
        component: () => import('@/views/article/create_and_edit'),
        name: '编辑动态',
        meta: { title: '动态', login: true }
      },

      // 视频管理
      {
        path: '/video/list',
        component: () => import('@/views/videos'),
        name: '视频管理',
        meta: { title: '视频', login: true }
      },
      {
        path: '/video/add',
        component: () => import('@/views/videos/create_and_edit'),
        name: '编辑视频',
        meta: { title: '视频', login: true }
      },
      {
        path: '/video/edit',
        component: () => import('@/views/videos/create_and_edit'),
        name: '编辑视频',
        meta: { title: '视频', login: true }
      },

      // 预订管理
      {
        path: '/booking/verification',
        component: () => import('@/views/booking/verification'),
        name: '',
        meta: { title: '签到核验', login: true }
      },
      {
        path: '/booking/reservation',
        component: () => import('@/views/booking/reservation'),
        name: 'reservation',
        meta: { title: '预约管理', login: true }
      },
      {
        path: '/booking/venues',
        component: () => import('@/views/booking/venues'),
        name: '',
        meta: { title: '场地管理', login: true }
      },
      {
        path: '/booking/venues/add',
        component: () => import('@/views/booking/create_edit_venue'),
        name: 'venueAdd',
        meta: { title: '场地管理', login: true }
      },
      {
        path: '/booking/venues/edit',
        component: () => import('@/views/booking/create_edit_venue'),
        name: 'venueAdd',
        meta: { title: '场地管理', login: true }
      },
      {
        path: '/booking/reservation/edit',
        component: () => import('@/views/booking/create_edit_reservation'),
        name: 'reservationEdit',
        meta: { title: '预约管理', login: true }
      },

      // 活动管理
      {
        path: '/eventines',
        component: () => import('@/views/eventine/eventines'),
        name: '',
        meta: { title: '活动管理', login: true }
      },
      {
        path: '/eventines/add',
        component: () => import('@/views/eventine/create_edit_eventine'),
        name: 'venueAdd',
        meta: { title: '活动管理', login: true }
      },
      {
        path: '/eventines/edit',
        component: () => import('@/views/eventine/create_edit_eventine'),
        name: 'venueAdd',
        meta: { title: '活动管理', login: true }
      },
      {
        path: '/eventines/reservations',
        component: () => import('@/views/eventine/reservations'),
        name: 'reservation',
        meta: { title: '活动预约', login: true }
      },

      // 云运动
      {
        path: '/cloud/line',
        component: () => import('@/views/cloud/line'),
        name: '',
        meta: { title: '路线', login: true }
      },
      {
        path: '/cloud/line/add',
        component: () => import('@/views/cloud/lineAdd'),
        name: 'LineAdd',
        meta: { title: '路线添加', login: true }
      },
      {
        path: '/cloud/line/edit',
        component: () => import('@/views/cloud/lineEdit'),
        name: '',
        meta: { title: '路线修改', login: true }
      },
      {
        path: '/cloud/sport',
        component: () => import('@/views/cloud/sport'),
        name: '',
        meta: { title: '坐标', login: true }
      },
      {
        path: '/cloud/get/point',
        component: () => import('@/views/cloud/getPoint'),
        name: '',
        meta: { title: '获取坐标', login: true }
      },

      // go运功
      {
        path: '/go',
        component: () => import('@/views/go'),
        name: 'index',
        meta: { title: 'go运功', login: true }
      },

      // 商城
      {
        path: '/shop/index',
        component: () => import('@/views/shop'),
        name: 'index',
        meta: { title: '商城-兑换商品', login: true }
      },
      {
        path: '/product/add',
        component: () => import('@/views/shop/create_and_edit'),
        name: '编辑商品',
        meta: { title: '商品', login: true }
      },
      {
        path: '/product/edit',
        component: () => import('@/views/shop/create_and_edit'),
        name: '编辑商品',
        meta: { title: '商品', login: true }
      },
      {
        path: '/shop/exchanges',
        component: () => import('@/views/shop/exchanges'),
        name: 'index',
        meta: { title: '积分商城-兑换订单', login: true }
      },

      // 用户
      {
        path: '/user/index',
        component: () => import('@/views/user'),
        name: 'index',
        meta: { title: '用户管理', login: true }
      },
      {
        path: '/user/add',
        component: () => import('@/views/user/userAdd'),
        name: 'index',
        meta: { title: '用户-添加', login: true }
      },
      {
        path: '/user/edit',
        component: () => import('@/views/user/userEdit'),
        name: 'index',
        meta: { title: '用户-修改', login: true }
      },

      // 角色
      {
        path: '/roles/index',
        component: () => import('@/views/roles'),
        name: 'index',
        meta: { title: '角色管理', login: true }
      },
      // 角色-添加
      {
        path: '/roles/add',
        component: () => import('@/views/roles/rolesAdd'),
        name: '',
        meta: { title: '角色添加', login: true }
      },
      // 角色-修改
      {
        path: '/roles/edit',
        component: () => import('@/views/roles/rolesEdit'),
        name: '',
        meta: { title: '角色修改', login: true }
      },

      // 小程序
      {
        path: '/wxapp/index',
        component: () => import('@/views/wxapp'),
        redirect: '/wxapp/banner',
        children: [
          // 位置
          {
            path: '/wxapp/banner/location',
            component: () => import('@/views/wxapp/bannerLocation'),
            name: '',
            meta: { title: '位置列表', login: true }
          },
          {
            path: '/wxapp/banner/location/add',
            component: () => import('@/views/wxapp/bannerLocationAdd'),
            name: '',
            meta: { title: '位置添加', login: true }
          },
          {
            path: '/wxapp/banner/location/edit',
            component: () => import('@/views/wxapp/bannerLocationEdit'),
            name: '',
            meta: { title: '位置修改', login: true }
          },
          {
            path: '/wxapp/banner',
            component: () => import('@/views/wxapp/bannerLocationInfo'),
            name: '',
            meta: { title: 'Banner', login: true }
          },
          // banner 
          {
            path: '/wxapp/banner/add',
            component: () => import('@/views/wxapp/bannerAdd'),
            name: '',
            meta: { title: 'banner', login: true }
          },
          // banner 
          {
            path: '/wxapp/banner/edit',
            component: () => import('@/views/wxapp/bannerEdit'),
            name: '',
            meta: { title: 'banner', login: true }
          },

          {
            path: '/wxapp/set/live',
            component: () => import('@/views/wxapp/setLive'),
            name: '',
            meta: { title: '小程序-直播页设置', login: true }
          },
        ],
        meta: { title: '小程序-页面设置', login: true }
      },

      // 权限
      {
        path: '/power',
        component: () => import('@/views/power'),
        name: '',
        meta: { title: '权限-管理', login: true }
      },
      {
        path: '/power/add',
        component: () => import('@/views/power/powerAdd'),
        name: '',
        meta: { title: '权限-添加', login: true }
      },
      {
        path: '/power/edit',
        component: () => import('@/views/power/powerEdit'),
        name: '',
        meta: { title: '权限-修改', login: true }
      },

      // 菜单管理
      {
        path: '/menu/about',
        component: () => import('@/views/menuAbout'),
        name: 'menuAbout',
        meta: { title: '菜单-管理', login: true }
      },
      {
        path: '/menu/edit',
        component: () => import('@/views/menuAbout/menuEdit'),
        name: '',
        meta: { title: '菜单-编辑', login: true }
      },
      {
        path: '/menu/about/add',
        component: () => import('@/views/menuAbout/menuAdd'),
        name: 'menuAbout',
        meta: { title: '菜单-添加菜单', login: true }
      },

      // 附件
      {
        path: '/assets',
        component: () => import('@/views/assets'),
        name: 'assetList',
        meta: { title: '附件文件', login: true }
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//全局前置守卫
router.beforeEach((to, from, next) => {
  document.title = to.meta.title + ' | 城市漫步'
  if (to.meta.login && !store.state.token) {
    next("/login")
  } else {
    next()
  }
})

export default router
